export enum LEGACY_PRODUCT_TYPES {
    TRIAL = 'TRIAL',
    FREEBIE = 'FREEBIE',
    ADYEN = 'ADYEN',
    GOOGLE = 'GOOGLE',
    APPLE = 'APPLE',
    AMAZON = 'AMAZON',
    PARTNER = 'PARTNER',
    B2B = 'B2B',
    CORPORATE_HEALTH = 'HEALTH',
}

export enum PRODUCT_TYPE {
    PAID = 'PAID',
    FREEBIE = 'FREEBIE',
    CORPORATE = 'CORPORATE',
    PREVENTION = 'PREVENTION',
}

export enum PAYMENT_HANDLER {
    ADYEN = 'ADYEN',
    AMAZON = 'AMAZON',
    APPLE = 'APPLE',
    GOOGLE = 'GOOGLE',
    NONE = 'NONE',
}

export enum ChipSizes {
    xs = 'xs',
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export enum ChipBackgroundColors {
    white = 'white',
    beige = 'beige',
    transparent = 'transparent',
}

export interface ChipProps {
    className?: string
    text?: string
    avatar?: string
    stretch?: boolean
    selected?: boolean
    disabled?: boolean
    clickable?: boolean
    size?: keyof typeof ChipSizes
    endIcon?: React.FunctionComponent
    startIcon?: React.FunctionComponent
    backgroundColor?: keyof typeof ChipBackgroundColors
    classes?: {
        startIcon?: string
        endIcon?: string
    }
}

import cn from 'classnames'
import { useIntl } from 'react-intl'
import React, { useCallback } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import InputLabel from '@mui/material/InputLabel'
import MuiTextField from '@mui/material/TextField'

import { Sizes } from '../types'
import Typography from 'typography'
import { TextFieldProps } from './types'
import { getInputSlotsProps } from './utils'

import style from './text-field.module.scss'

const TextField: React.FC<TextFieldProps & UseControllerProps> = ({
    className,
    name,
    classes,
    id,
    label,
    InputLabelProps,
    StartSlot,
    EndSlot,
    size = Sizes.medium,
    InputProps = {},
    FormHelperTextProps = {},
    children,
    placeholder,
    helperText,
    onChange: customOnChange,
    getValue,
    error: forceError,
    ...restProps
}) => {
    const intl = useIntl()

    const {
        field: { onChange, onBlur, value, name: formName, ref },
        fieldState: { error: formFieldError },
    } = useController({ name, ...restProps })

    const error = formFieldError || forceError

    const formControlId = id || formName
    const inputSlotsProps = getInputSlotsProps(StartSlot, EndSlot, !!error)

    const handleChange = useCallback(
        event => {
            if (typeof customOnChange === 'function') {
                onChange(customOnChange(event))
            }

            return onChange(event)
        },
        [customOnChange, onChange]
    )

    return (
        <section className={cn(style.wrapper, className)}>
            {label && (
                <InputLabel htmlFor={formControlId} id={`${formControlId}-label`} {...InputLabelProps}>
                    <Typography as="span" variant="body1" color="main" className={classes?.label}>
                        {typeof label === 'string' ? intl.formatMessage({ id: label }) : label}
                    </Typography>
                </InputLabel>
            )}

            <MuiTextField
                id={formControlId}
                name={formName}
                className={cn(style.textField, { [style.inputLarge]: size === Sizes.large }, classes?.root)}
                variant="outlined"
                error={!!error}
                value={getValue ? getValue(value) : value}
                onChange={handleChange}
                onBlur={onBlur}
                inputRef={ref}
                placeholder={placeholder && intl.formatMessage({ id: placeholder })}
                helperText={formFieldError?.message ? intl.formatMessage({ id: formFieldError?.message }) : helperText}
                FormHelperTextProps={{
                    classes: { root: style.helperText },
                    variant: 'standard',
                    ...FormHelperTextProps,
                }}
                InputProps={{
                    ...InputProps,
                    ...inputSlotsProps,
                }}
                {...restProps}
            >
                {children}
            </MuiTextField>
        </section>
    )
}

export default TextField

import { ChipProps } from 'atoms/chip/types'

export enum ItemGroupVariants {
    tile = 'tile',
    list = 'list',
    chip = 'chip',
}

export interface ChipItem extends ChipProps {
    value: string
}

export interface TileAndListItem {
    icon?: React.FunctionComponent
    title: string
    subtitle?: string
    value: string
}

interface ItemsGroupBaseProps {
    className?: string
    itemClassName?: string
    name: string
    variant?: keyof typeof ItemGroupVariants
    multiple?: boolean
    disabled?: boolean
    tileItemXsCol?: number
    tileItemSmCol?: number
    tileItemMdCol?: number
    tileItemLgCol?: number
}

export interface ItemsGroupChipProps extends ItemsGroupBaseProps {
    variant: 'chip'
    items: ChipItem[]
}

export interface ItemsGroupTileAndListProps extends ItemsGroupBaseProps {
    variant: 'tile' | 'list'
    items: TileAndListItem[]
}

export type ItemGroupProps = ItemsGroupChipProps | ItemsGroupTileAndListProps

interface ItemPropsBase {
    className?: string
    variant: keyof typeof ItemGroupVariants
    disabled?: boolean
    isSelected: boolean
    onClick: (value: string) => void
}

export interface ChipItemProps extends ItemPropsBase {
    variant: 'chip'
    item: ChipItem
}

export interface TileAndListItemProps extends ItemPropsBase {
    variant: 'tile' | 'list'
    item: TileAndListItem
}

export type ItemProps = ChipItemProps | TileAndListItemProps

import React from 'react'
import { FormattedMessage } from 'react-intl'

interface RichTextProps {
    id?: string
    htmlText?: string
    textVariables?: Record<string, string | React.ReactNode>
}

const RichTextTags = {
    h1: (parts: React.ReactNode[]) => (
        <>
            {parts} <br />
        </>
    ),
    p: (parts: React.ReactNode[]) => (
        <>
            {parts} <br />
        </>
    ),
    strong: (parts: React.ReactNode[]) => <strong>{parts}</strong>,
    em: (parts: React.ReactNode[]) => <em>{parts}</em>,
}

const RichText: React.FC<RichTextProps> = ({ htmlText = '', textVariables = {}, id = ' ' }) => (
    <FormattedMessage id={id} defaultMessage={htmlText} values={{ ...RichTextTags, ...textVariables }} />
)

export default RichText

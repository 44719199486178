import React from 'react'
import cn from 'classnames'

import Icon from 'components/icon'
import Typography from 'typography'
import {
    TileAndListItemProps as ItemProps,
    TileAndListItem as TileAndListOption,
    ItemGroupVariants,
} from 'components/form/controls/item-group/types'

import style from './tile-and-list-item.module.scss'

export type TileAndListItemProps = Omit<ItemProps, 'item'> & Omit<TileAndListOption, 'value'>

const TileAndListItem: React.FC<TileAndListItemProps & React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    isSelected,
    variant,
    disabled,
    icon,
    title,
    subtitle,
    ...restProps
}) => {
    return (
        <div
            className={cn(style.itemContainer, className, {
                [style.tile]: variant === ItemGroupVariants.tile,
                [style.selected]: isSelected,
                [style.disabled]: disabled,
            })}
            {...restProps}
        >
            {icon && (
                <section className={style.iconContainer}>
                    <Icon
                        data={icon}
                        className={cn(style.icon, {
                            [style.selected]: isSelected,
                            [style.disabled]: disabled,
                        })}
                    />
                </section>
            )}

            <section>
                <Typography
                    as="header"
                    variant="body2"
                    color={(disabled && 'gray') || (isSelected ? 'inverted' : 'main')}
                >
                    {title}
                </Typography>

                {subtitle && variant !== ItemGroupVariants.tile && (
                    <Typography
                        className={cn(style.subtitle, {
                            [style.selected]: isSelected,
                            [style.disabled]: disabled,
                        })}
                        as="p"
                        variant="caption"
                        color={(disabled && 'gray') || (isSelected ? 'inverted' : 'gray')}
                    >
                        {subtitle}
                    </Typography>
                )}
            </section>
        </div>
    )
}

export default TileAndListItem

import React from 'react'
import cn from 'classnames'

import Icon from 'components/icon'
import Typography from 'typography'
import { ChipSizes, ChipBackgroundColors, ChipProps } from './types'

import style from './chip.module.scss'

const Chip: React.FC<ChipProps & React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    classes,
    text,
    avatar,
    startIcon,
    endIcon,
    stretch,
    selected,
    disabled,
    clickable = true,
    size = ChipSizes.xs,
    backgroundColor = ChipBackgroundColors.white,
    children,
    ...restProps
}) => (
    <div
        className={cn(
            style.container,
            style[`size-${size}`],
            style[`bg-${backgroundColor}`],
            {
                [style.centerText]: !startIcon && !avatar && !endIcon,
                [style.stretch]: stretch,
                [style.selected]: selected,
                [style.disabled]: disabled,
                [style.hasAvatar]: !!avatar,
                [style.static]: !clickable,
            },
            className
        )}
        {...restProps}
    >
        <div className={cn(style.textWrapper)}>
            {startIcon && !avatar && <Icon className={cn(style.startIcon, classes?.startIcon)} data={startIcon} />}
            {avatar && <img src={avatar} className={style.avatar} alt={text} />}

            <Typography className={style.text} variant="caption">
                {text}
                {children}
            </Typography>
        </div>

        {endIcon && (
            <div className={style.endIconWrapper}>
                <Icon className={cn(style.endIcon, classes?.endIcon)} data={endIcon} />
            </div>
        )}
    </div>
)

export default Chip

import axios from 'axios'
import jsCookie from 'js-cookie'

import requestInterceptor from '../request-interceptor/request-interceptor'
import { COOKIE_NAMES, HEADERS } from '../../enums'
import responseInterceptor from '../response-interceptor/response-interceptor'
import { setCookiesToken } from '../service'

export const _getConfigureRequestFunc = getUserLocale => config => {
    const params = {
        locale: getUserLocale(),
    }
    const ACCESS_TOKEN = jsCookie.get(COOKIE_NAMES.OAUTH2_ACCESS_TOKEN)
    const REFRESH_TOKEN = jsCookie.get(COOKIE_NAMES.OAUTH2_REFRESH_TOKEN)
    const ID_TOKEN = jsCookie.get(COOKIE_NAMES.OAUTH2_ID_TOKEN)

    const headers = {
        ...(ACCESS_TOKEN && { [HEADERS.ACCESS_TOKEN]: ACCESS_TOKEN }),
        ...(ID_TOKEN && { [HEADERS.ID_TOKEN]: ID_TOKEN }),
        ...(REFRESH_TOKEN && { [HEADERS.REFRESH_TOKEN]: REFRESH_TOKEN }),
    }

    return {
        ...config,
        params: {
            ...params,
            ...config.params,
        },
        headers: {
            ...config.headers,
            ...headers,
        },
    }
}

const processResponse = handleUserInfoHeader => response => {
    setCookiesToken({
        access_token: response.headers[HEADERS.ACCESS_TOKEN],
        id_token: response.headers[HEADERS.ID_TOKEN],
        refresh_token: response.headers[HEADERS.REFRESH_TOKEN],
    })

    if (response.headers[HEADERS.USERINFO] && handleUserInfoHeader) {
        let decodedUserInfo

        try {
            decodedUserInfo = JSON.parse(window.atob(response.headers[HEADERS.USERINFO]))
        } catch (err) {
            console.error('Error decoding user info', err)
        }

        if (decodedUserInfo) {
            handleUserInfoHeader(decodedUserInfo)
        }
    }

    return response
}

const crudGeneric = (constants, funcs) => {
    let axiosInstance = axios.create()
    const configureRequest = _getConfigureRequestFunc(funcs.getUserLocale)
    const responseErrorHandler = err => {
        if (err.response?.status === 401) {
            funcs.refreshAccessTokenError(err)
        }
        return Promise.reject(err)
    }

    axiosInstance = requestInterceptor(axiosInstance, configureRequest)
    axiosInstance = responseInterceptor(
        axiosInstance,
        processResponse(funcs.handleUserInfoHeader),
        responseErrorHandler
    )

    return axiosInstance
}

export default crudGeneric

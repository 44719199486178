export enum VOUCHER_TYPES {
    DISCOUNT = 'Discount',
    COOPERATION = 'CooperationSignup',
    TRIAL = 'TrialSignup',
    FREEBIE = 'FreeSignup',
}

export enum DISCOUNT_TYPES {
    RELATIVE = 'RelativeDiscount',
    FIXED = 'FixedDiscount',
}

export enum DISCOUNT_VOUCHER_SUBTYPES {
    DEFAULT = 'DEFAULT',
    WINBACK = 'WINBACK',
}

import { useIntl } from 'react-intl'
import React, { useCallback } from 'react'

import { Chip } from 'atoms'
import TileAndListItem from './component/tile-and-list-item'
import {
    ItemProps,
    ChipItem,
    TileAndListItem as TileAndListOption,
    ItemGroupVariants,
} from 'components/form/controls/item-group/types'

const Item = ({ className, isSelected, variant, onClick, disabled, item }: ItemProps) => {
    const intl = useIntl()

    const handleOnClick = useCallback((value, isDisabled) => !isDisabled && onClick(value), [onClick])

    if (variant === ItemGroupVariants.chip) {
        const { disabled: chipDisabled, text, value, ...restProps } = item as ChipItem

        const formattedText = intl.formatMessage({ id: text })
        const isDisabled = disabled || chipDisabled

        return (
            <Chip
                className={className}
                text={formattedText}
                selected={isSelected}
                disabled={isDisabled}
                onClick={() => handleOnClick(value, isDisabled)}
                role="option"
                aria-selected={!!isSelected}
                aria-label={formattedText}
                {...restProps}
            />
        )
    }

    const { title, subtitle, value, ...restItemProps } = item as TileAndListOption

    const formattedTitle = intl.formatMessage({ id: title })
    const formattedSubtitle = subtitle && intl.formatMessage({ id: subtitle })

    return (
        <TileAndListItem
            className={className}
            isSelected={isSelected}
            disabled={disabled}
            onClick={() => handleOnClick(value, disabled)}
            variant={variant}
            role="option"
            aria-selected={!!isSelected}
            aria-label={formattedTitle}
            title={formattedTitle}
            subtitle={formattedSubtitle}
            {...restItemProps}
        />
    )
}

export default Item
